import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  LegacyDeskConfig,
  getDeskConfig,
  updateDeskConfig,
  LIFECYCLE_SP_LANDING_PAGES,
  SP_PM_PILL_FILTER_OPTIONS,
} from '../../API/preference.service';
import { useForm } from '@mantine/form';
import {
  Button,
  Divider,
  Group,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';

interface CompanyLevelConfigProps {
  legacyDeskId: number;
}

/**
 *
 * @param companyID: LEGACY id for a company
 * @constructor
 */
export const LegacyCompanyLevelConfig = ({
  legacyDeskId,
}: CompanyLevelConfigProps) => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const queryClient = useQueryClient();
  const {
    isLoading: getConfigLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ['getDeskConfig', legacyDeskId],
    queryFn: () => getDeskConfig(legacyDeskId),
    cacheTime: 0,
    onSuccess: (res) => {
      deskConfigForm.setValues({
        ...res.data,
        deskId: legacyDeskId,
        dailySummaryHourUtc: res.data.dailySummaryHourUtc?.toString(),
      } as any);
      deskConfigForm.resetDirty({
        ...res.data,
        deskId: legacyDeskId,
        dailySummaryHourUtc: res.data.dailySummaryHourUtc?.toString(),
      } as any);
    },
  });

  const {
    mutate: mutateDeskConfig,
    isLoading: updatingDeskConfig,
    isError: errorUpdateingDeskConfig,
  }: any = useMutation((config: LegacyDeskConfig) => updateDeskConfig(config), {
    onSuccess: () => {
      notifications.update({
        id: 'save-legacy-desk-config',
        title: 'Update Successful',
        message: 'Desk Config Updated Successfully.',
        loading: false,
        autoClose: false,
        withCloseButton: true,
        color: 'green',
      });
      queryClient.invalidateQueries(['getDeskConfig', legacyDeskId]);
    },
    onError: () => {
      notifications.update({
        id: 'save-legacy-desk-config',
        title: 'Error Submitting Product',
        loading: false,
        message: 'Failed to update desk config at this time.',
        withCloseButton: true,
        color: 'red',
      });
    },
  });

  const deskConfigForm = useForm({
    initialValues: {
      deskId: legacyDeskId,
      id: undefined,
      tisResourceEnabled: false,
      enableAnnuityLM2: false,
      displayExternalStructureName: false,
      displayBloombergTicker: false,
      displayProductSymbol: false,
      dailySummaryOccurrence: undefined,
      displayLegacyDashboard: true,
      displayLegacyNavigation: false,
      marketDataSourcePreference: undefined,
      hideDashboardWidgets: false,
      hideAllDisclaimer: false,
      displayIssuerNameWithLogo: false,
      displayMarkToMarketSource: false,
      enableDashboardInsights: false,
      displayLegacyLifecycleManager: false,
      hideProductMarketplaceMenu: false,
      hideHomeMenu: false,
      hidePoweredByLuma: false,
      showIncomeCompare: false,
      lifecycleSPLandingPage: undefined,
      configurableDashboardDefaultDesk: false,
      hideResourceCenter: false,
      hideLifecycleManagerMenu: false,
      canViewLikelihoodOfCall: false,
      enableLMProductCards: false,
      defaultToProductCardView: false,
      canViewAccountGraphicalView: false,
      showIntrinsicValue: false,
      hideCompositeFromAveragingDetails: false,
      canViewDeskLastSentEmail: false,
      dailySummaryHourUtc: null,
      enablePortfolioReport: false,
      allowANOESDocumentUpload: false,
      hideMarketplaceSPBuy: false,
      allowCustomizeAnnuityAppName: false,
      enableAccountsOfficeTab: false,
      enableAccountsHouseholdTab: false,
      enableAnnuityLnACheck: false,
      enableNewSPMarketplace: false,
      enableMarketplaceDirectLink: false,
      canViewAddeparIntegration: false,
      allowAnnuityBuyWOTraining: false,
      enableProductDetailsV2: false,
      enableFeatureChips: true,
      spPmPillFilters: [],
      displayAdvisorInsteadOfRep: false,
      enableReactAnnuityPM: false,
      enableReactSPPM: false,
      useDMESUserlistAsWhitelist: false,
      enableLifeInsurancePM: false,
      configurableDashboard: {
        enableConfigurableDashboard: false,
        headerNameOverride: null,
        configurableDashboardDefaultDesk: false,
        isLivePortfolioSummaryAvailable: false,
        isLivePortfolioDetailAvailable: false,
        isITMNotionalAvailable: false,
        isUpcomingEventBreakdownStackedAvailable: false,
        isUpcomingEventBreakdownNonStackedAvailable: false,
        isExposureBreakdownAvailable: false,
        isOverviewSummaryDetailsAvailable: false,
        isMyHoldingsAvailable: false,
        isRemainingTimeToMaturityAvailable: false,
        isPortfolioHealthSummaryAvailable: false,
        isProjectedCashFlowTimelineHeatmapAvailable: false,
        isProjectedCashFlowAvailable: false,
        isPendingPricingRequestsIssuerAvailable: false,
        isPendingPricingRequestsFAAvailable: false,
        isCreationTemplatesAvailable: false,
        isApplicationTrackerAvailable: false,
        isProductMarketplaceOfferingsAvailable: false,
        isContactsAvailable: false,
      },
    },
  });

  // Get the dashboard values for the switches
  const displayLegacyDashboard = deskConfigForm.values.displayLegacyDashboard;
  const enableConfigurableDashboard =
    deskConfigForm.values.configurableDashboard.enableConfigurableDashboard;

  // useEffect hook to ensure at least one dashboard is always visible
  useEffect(() => {
    if (!displayLegacyDashboard && !enableConfigurableDashboard) {
      // If both are false, set one of them to true
      deskConfigForm.setFieldValue('displayLegacyDashboard', true);
    }
  }, [displayLegacyDashboard, enableConfigurableDashboard, deskConfigForm]);

  useEffect(() => {
    deskConfigForm.reset();
  }, [legacyDeskId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitDeskConfig = () => {
    notifications.show({
      id: 'save-legacy-desk-config',
      title: 'Submitting',
      message: 'Saving Desk Config...',
      loading: true,
      autoClose: false,
      withCloseButton: false,
      color: 'blue',
    });
    mutateDeskConfig(deskConfigForm.values);
  };

  const [enableLMProductCards, setEnableLMProductCards] = useState(
    deskConfigForm.values.enableLMProductCards,
  );
  const [defaultToProductCardView, setDefaultToProductCardView] = useState(
    deskConfigForm.values.defaultToProductCardView,
  );

  const handleEnableLMProductCardsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    setEnableLMProductCards(checked);
    deskConfigForm.setFieldValue('enableLMProductCards', checked);

    // If enableLMProductCards is turned off, automatically turn off defaultToProductCardView
    if (!checked) {
      setDefaultToProductCardView(false);
      deskConfigForm.setFieldValue('defaultToProductCardView', false);
    }
  };

  const handleDefaultToProductCardViewChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;

    if (enableLMProductCards) {
      setDefaultToProductCardView(checked);
      deskConfigForm.setFieldValue('defaultToProductCardView', checked);
    }
  };

  const DAILY_SUMMARY_HOUR_MIN = 1;
  const DAILY_SUMMARY_HOUR_MAX = 24;

  const dailySummaryHourSelections = Array.from(
    { length: DAILY_SUMMARY_HOUR_MAX - DAILY_SUMMARY_HOUR_MIN },
    (_, i) => ({
      label: `${(i + DAILY_SUMMARY_HOUR_MIN).toString().padStart(2, '0')}:00`,
      value: (i + DAILY_SUMMARY_HOUR_MIN).toString(),
    }),
  );

  return (
    <section
      style={{
        marginBottom: '10rem',
        marginLeft: '2rem',
        width: 'fit-content',
      }}
    >
      <LoadingOverlay
        visible={getConfigLoading || updatingDeskConfig}
        overlayProps={{ blur: 1 }}
      />
      {isError ? (
        <>
          <Text ta={'center'} style={{ color: 'red', weight: 600 }}>
            Something went wrong
          </Text>
          <Text>Unable To load Desk Configuration</Text>
          <Button
            variant={'primary'}
            style={{ width: '100%' }}
            disabled={isRefetching}
            onClick={() => refetch()}
          >
            Retry
          </Button>
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '1rem',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <h3 style={{ margin: '0', marginRight: '1rem' }}>
              Desk Level Configurations
            </h3>
            <Button
              variant={'primary'}
              style={{ minWidth: '10rem' }}
              disabled={!deskConfigForm.isDirty()}
              onClick={handleSubmitDeskConfig}
            >
              Save
            </Button>
          </div>
          <div style={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}>
            <Paper
              shadow="sm"
              p="md"
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                minWidth: 'max-content',
                flexGrow: '1',
                border: `1px solid ${errorUpdateingDeskConfig ? 'red' : 'gray'}`,
              }}
            >
              <Stack gap={'xs'}>
                <h4>General Configurations</h4>
                {/*todo: this should be a select?*/}
                {/*dailySummaryOccurrence*/}
                {/*todo: whats this field look like?*/}
                {/*marketDataSourcePreference: string, // null,*/}
                <Switch
                  label={'Enable Annuity LM2'}
                  {...deskConfigForm.getInputProps('enableAnnuityLM2', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Display External Structure Name'}
                  {...deskConfigForm.getInputProps(
                    'displayExternalStructureName',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'TIS Resource Enabled (Shows in PM grid)'}
                  {...deskConfigForm.getInputProps('tisResourceEnabled', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Display Bloomberg Ticker'}
                  {...deskConfigForm.getInputProps('displayBloombergTicker', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Display Product Symbol'}
                  {...deskConfigForm.getInputProps('displayProductSymbol', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Display Legacy Dashboard'}
                  {...deskConfigForm.getInputProps('displayLegacyDashboard', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Display Legacy Navigation'}
                  {...deskConfigForm.getInputProps('displayLegacyNavigation', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Hide Dashboard Widgets'}
                  {...deskConfigForm.getInputProps('hideDashboardWidgets', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Hide Footer (disclaimers)'}
                  {...deskConfigForm.getInputProps('hideAllDisclaimer', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Display Issuer Name with Logo'}
                  {...deskConfigForm.getInputProps(
                    'displayIssuerNameWithLogo',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Display Mark To Market Source'}
                  {...deskConfigForm.getInputProps(
                    'displayMarkToMarketSource',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Enable Dashboard Insights'}
                  {...deskConfigForm.getInputProps('enableDashboardInsights', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Display Legacy Lifecycle Manager'}
                  {...deskConfigForm.getInputProps(
                    'displayLegacyLifecycleManager',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Hide Product Marketplace Menu'}
                  {...deskConfigForm.getInputProps(
                    'hideProductMarketplaceMenu',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Hide Home Menu'}
                  {...deskConfigForm.getInputProps('hideHomeMenu', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Hide Powered By Luma'}
                  {...deskConfigForm.getInputProps('hidePoweredByLuma', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Enable Income Comparison'}
                  {...deskConfigForm.getInputProps('showIncomeCompare', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Hide Resource Center'}
                  {...deskConfigForm.getInputProps('hideResourceCenter', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Hide Lifecycle Manager Menu'}
                  {...deskConfigForm.getInputProps('hideLifecycleManagerMenu', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Can View Likelihood Of Call'}
                  {...deskConfigForm.getInputProps('canViewLikelihoodOfCall', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Can View LM Product Cards'}
                  {...deskConfigForm.getInputProps('enableLMProductCards', {
                    type: 'checkbox',
                  })}
                  checked={enableLMProductCards}
                  onChange={handleEnableLMProductCardsChange}
                />
                <Switch
                  label={'LM Product Cards View as Default'}
                  {...deskConfigForm.getInputProps('defaultToProductCardView', {
                    type: 'checkbox',
                  })}
                  checked={defaultToProductCardView}
                  disabled={!enableLMProductCards}
                  onChange={handleDefaultToProductCardViewChange}
                />
                <Switch
                  label={'Can View LM Account Graphical View'}
                  {...deskConfigForm.getInputProps(
                    'canViewAccountGraphicalView',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Show Intrinsic Value (LM)'}
                  {...deskConfigForm.getInputProps('showIntrinsicValue', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Display Rep as Advisor (LM)'}
                  {...deskConfigForm.getInputProps(
                    'displayAdvisorInsteadOfRep',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Hide Composite From Averaging Details (LM)'}
                  {...deskConfigForm.getInputProps(
                    'hideCompositeFromAveragingDetails',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Can View Desk Level Last Sent Data'}
                  {...deskConfigForm.getInputProps('canViewDeskLastSentEmail', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Can View Portfolio Report'}
                  {...deskConfigForm.getInputProps('enablePortfolioReport', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Allow Annuities OES Document Upload'}
                  {...deskConfigForm.getInputProps('allowANOESDocumentUpload', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Hide Buy SP Marketplace'}
                  {...deskConfigForm.getInputProps('hideMarketplaceSPBuy', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Allow Customize Annuity App Name'}
                  {...deskConfigForm.getInputProps(
                    'allowCustomizeAnnuityAppName',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Enable Office Accounts Tab'}
                  {...deskConfigForm.getInputProps('enableAccountsOfficeTab', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Enable Household Accounts Tab'}
                  {...deskConfigForm.getInputProps(
                    'enableAccountsHouseholdTab',
                    {
                      type: 'checkbox',
                    },
                  )}
                />
                <Switch
                  label={'Enable Annuity License and Appointment Check'}
                  {...deskConfigForm.getInputProps('enableAnnuityLnACheck', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Enable New Product Marketplace (Structured Products)'}
                  {...deskConfigForm.getInputProps('enableNewSPMarketplace', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Enable Marketplace Direct Link'}
                  {...deskConfigForm.getInputProps(
                    'enableMarketplaceDirectLink',
                    {
                      type: 'checkbox',
                    },
                  )}
                />
                <Switch
                  label={'Can View Addepar Integration'}
                  {...deskConfigForm.getInputProps(
                    'canViewAddeparIntegration',
                    {
                      type: 'checkbox',
                    },
                  )}
                />
                <Switch
                  label={'Allow Annuity Buy Without Training'}
                  {...deskConfigForm.getInputProps(
                    'allowAnnuityBuyWOTraining',
                    {
                      type: 'checkbox',
                    },
                  )}
                />
                <Switch
                  label="Enable Product Details v2"
                  {...deskConfigForm.getInputProps('enableProductDetailsV2', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label="Enable Feature Chips"
                  {...deskConfigForm.getInputProps('enableFeatureChips', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Enable New Annuities PM'}
                  {...deskConfigForm.getInputProps('enableReactAnnuityPM', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={'Enable New SP PM'}
                  {...deskConfigForm.getInputProps('enableReactSPPM', {
                    type: 'checkbox',
                  })}
                />
                <Switch
                  label={
                    'Use Daily/Montly Summary Email User List as a Whitelist'
                  }
                  {...deskConfigForm.getInputProps(
                    'useDMESUserlistAsWhitelist',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Enable Life Insurance Product Marketplace'}
                  {...deskConfigForm.getInputProps('enableLifeInsurancePM', {
                    type: 'checkbox',
                  })}
                />
                <Select
                  label={'Default LM Landing Page'}
                  data={[
                    {
                      label: 'Events',
                      value: LIFECYCLE_SP_LANDING_PAGES.EVENTS,
                    },
                    {
                      label: 'Products',
                      value: LIFECYCLE_SP_LANDING_PAGES.PRODUCTS,
                    },
                    {
                      label: 'Accounts',
                      value: LIFECYCLE_SP_LANDING_PAGES.ACCOUNTS,
                    },
                  ]}
                  {...deskConfigForm.getInputProps('lifecycleSPLandingPage')}
                />
                <Select
                  label={'Daily Summary Hour (UTC)'}
                  data={dailySummaryHourSelections}
                  {...deskConfigForm.getInputProps('dailySummaryHourUtc')}
                />
                {/*<Switch label={''}*/}
                {/*        {...deskConfigForm.getInputProps('', {type: 'checkbox'})}/>*/}
                <Tooltip
                  label={
                    'This controls what chips appear above the grid in structured products marketplace, which are sets of filters.'
                  }
                >
                  <MultiSelect
                    style={{ width: '25rem' }}
                    label={'SP PM Pill Filters'}
                    {...deskConfigForm.getInputProps('spPmPillFilters')}
                    data={SP_PM_PILL_FILTER_OPTIONS}
                  />
                </Tooltip>
              </Stack>
            </Paper>

            <Paper
              shadow="sm"
              p="md"
              style={{
                position: 'relative',
                background: deskConfigForm.values.configurableDashboard
                  .enableConfigurableDashboard
                  ? 'inherit'
                  : colorScheme === 'dark'
                    ? theme.colors.dark[4]
                    : theme.colors.gray[0],
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                flexGrow: '1',
                border: `1px solid ${errorUpdateingDeskConfig ? 'red' : 'gray'}`,
                minWidth: 'max-content',
              }}
            >
              <Stack gap={'xs'}>
                <Group justify={'space-between'}>
                  <h4>Configurable Dashboard</h4>
                  <Switch
                    labelPosition={'left'}
                    label={
                      deskConfigForm.values.configurableDashboard
                        .enableConfigurableDashboard
                        ? 'Enabled'
                        : 'Disabled'
                    }
                    {...deskConfigForm.getInputProps(
                      'configurableDashboard.enableConfigurableDashboard',
                      { type: 'checkbox' },
                    )}
                  />
                </Group>
                <TextInput
                  style={{ marginBottom: '0.5rem' }}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.headerNameOverride',
                  )}
                  label={'Dashboard Header Name Override'}
                  placeholder={'Structured Products'}
                ></TextInput>
                <Divider />
                <Switch
                  label={'Configurable Dashboard as Default Dashboard'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.configurableDashboardDefaultDesk',
                    { type: 'checkbox' },
                  )}
                />
                <Divider />
                <h4 style={{ margin: '0' }}>Available Widgets</h4>
                <Switch
                  label={'Live Portfolio Summary'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isLivePortfolioSummaryAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Live Portfolio Detail'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isLivePortfolioDetailAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'ITM Notional'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isITMNotionalAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Upcoming Event Breakdown (Stacked)'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isUpcomingEventBreakdownStackedAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Upcoming Event Breakdown (Non Stacked)'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isUpcomingEventBreakdownNonStackedAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Exposure Breakdown'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isExposureBreakdownAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Overview Summary Details'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isOverviewSummaryDetailsAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'My Holdings'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isMyHoldingsAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Projected Cash Flow'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isProjectedCashFlowAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Pending Pricing Requests Issuer'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isPendingPricingRequestsIssuerAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Pending Pricing Requests FA'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isPendingPricingRequestsFAAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Creation Templates'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isCreationTemplatesAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Application Tracker'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isApplicationTrackerAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Product Marketplace Offerings'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isProductMarketplaceOfferingsAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Contacts'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isContactsAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Portfolio Health Summary'}
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isPortfolioHealthSummaryAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Remaining Time To Maturity (Coming Soon)'}
                  disabled
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isRemainingTimeToMaturityAvailable',
                    { type: 'checkbox' },
                  )}
                />
                <Switch
                  label={'Projected Cash Flow Timeline Heatmap (Coming Soon)'}
                  disabled
                  {...deskConfigForm.getInputProps(
                    'configurableDashboard.isProjectedCashFlowTimelineHeatmapAvailable',
                    { type: 'checkbox' },
                  )}
                />
              </Stack>
            </Paper>
          </div>
        </>
      )}
    </section>
  );
};
