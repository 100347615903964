import axios, { AxiosResponse } from 'axios';
import { PREFERENCE_SERVICE_ROOT } from './api-consts';

export enum LIFECYCLE_SP_LANDING_PAGES {
  ACCOUNTS = 'ACCOUNTS',
  EVENTS = 'EVENTS',
  PRODUCTS = 'PRODUCTS',
}

export type SpPmPillFilterKey =
  | 'singleStockIncomeChip'
  | 'conservativeIncomeChip'
  | 'aggressiveIncomeChip'
  | 'shortTermPrincipallyProtectedGrowthChip';

export const SP_PM_PILL_FILTER_OPTIONS: {
  label: string;
  value: Extract<SpPmPillFilterKey, string>;
}[] = [
  {
    label: 'Single Stock Income',
    value: 'singleStockIncomeChip',
  },
  {
    label: 'Conservative / Moderate Income',
    value: 'conservativeIncomeChip',
  },
  {
    label: 'Aggressive Income',
    value: 'aggressiveIncomeChip',
  },
  {
    label: 'Short Term Principally Protected Growth',
    value: 'shortTermPrincipallyProtectedGrowthChip',
  },
];

export interface LegacyDeskConfig {
  id: string; // "64944ba6faaf1d18a76a0444" //need id only if it is an update
  deskId: number; // 1285, //desk id
  enableAnnuityLM2: boolean;
  tisResourceEnabled: boolean;
  displayExternalStructureName: boolean; // false,
  displayBloombergTicker: boolean; // false,
  displayProductSymbol: boolean; // false,
  dailySummaryOccurrence: string; // "DAILY",
  displayLegacyDashboard: boolean; // null,
  displayLegacyNavigation: boolean; // null,
  marketDataSourcePreference: string; // null,
  hideDashboardWidgets: boolean; // false,
  hideAllDisclaimer: boolean; // false,
  displayIssuerNameWithLogo: boolean; // false,
  displayMarkToMarketSource: boolean; // false,
  enableDashboardInsights: boolean; // false,
  displayLegacyLifecycleManager: boolean; // false,
  hideProductMarketplaceMenu: boolean; // false,
  hideHomeMenu: boolean; // false,
  hidePoweredByLuma: boolean; // false,
  showIncomeCompare: boolean;
  lifecycleSPLandingPage: LIFECYCLE_SP_LANDING_PAGES;
  dailySummaryHourUtc: number | null;
  hideResourceCenter: boolean;
  hideLifecycleManagerMenu: boolean;
  canViewLikelihoodOfCall: boolean;
  enableLMProductCards: boolean;
  defaultToProductCardView: boolean;
  canViewAccountGraphicalView: boolean;
  showIntrinsicValue: boolean;
  hideCompositeFromAveragingDetails: boolean;
  canViewDeskLastSentEmail: boolean;
  enablePortfolioReport: boolean;
  allowANOESDocumentUpload: boolean;
  hideMarketplaceSPBuy: boolean;
  allowCustomizeAnnuityAppName: boolean;
  enableAccountsOfficeTab: boolean;
  enableAccountsHouseholdTab: boolean;
  enableAnnuityLnACheck: boolean;
  enableNewSPMarketplace: boolean;
  enableMarketplaceDirectLink: boolean;
  canViewAddeparIntegration: boolean;
  allowAnnuityBuyWOTraining: boolean;
  enableProductDetailsV2: boolean;
  enableFeatureChips: boolean;
  spPmPillFilters: SpPmPillFilterKey[];
  displayAdvisorInsteadOfRep: boolean;
  enableReactAnnuityPM: boolean;
  enableReactSPPM: boolean;
  useDMESUserlistAsWhitelist: boolean;
  enableLifeInsurancePM: boolean;
  configurableDashboard: {
    enableConfigurableDashboard: boolean;
    configurableDashboardDefaultDesk: boolean;
    headerNameOverride: string | null;
    isLivePortfolioSummaryAvailable: boolean;
    isLivePortfolioDetailAvailable: boolean;
    isITMNotionalAvailable: boolean;
    isUpcomingEventBreakdownStackedAvailable: boolean;
    isUpcomingEventBreakdownNonStackedAvailable: boolean;
    isExposureBreakdownAvailable: boolean;
    isOverviewSummaryDetailsAvailable: boolean;
    isMyHoldingsAvailable: boolean;
    isRemainingTimeToMaturityAvailable: boolean;
    isPortfolioHealthSummaryAvailable: boolean;
    isProjectedCashFlowTimelineHeatmapAvailable: boolean;
    isProjectedCashFlowAvailable: boolean;
    isPendingPricingRequestsIssuerAvailable: boolean;
    isPendingPricingRequestsFAAvailable: boolean;
    isCreationTemplatesAvailable: boolean;
    isApplicationTrackerAvailable: boolean;
    isProductMarketplaceOfferingsAvailable: boolean;
    isContactsAvailable: boolean;
  };
}

export const getDeskConfig = (
  deskId: number,
): Promise<AxiosResponse<LegacyDeskConfig>> => {
  return axios.get<LegacyDeskConfig>(
    `${PREFERENCE_SERVICE_ROOT}/display/desk/config`,
    { params: { deskId } },
  );
};
export const updateDeskConfig = (deskConfig: LegacyDeskConfig) => {
  return axios.put<LegacyDeskConfig>(
    `${PREFERENCE_SERVICE_ROOT}/display/desk/config`,
    deskConfig,
  );
};
